"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MonkApi = void 0;
var inspection_1 = require("./inspection");
var image_1 = require("./image");
var task_1 = require("./task");
var liveConfigs_1 = require("./liveConfigs");
var vehicle_1 = require("./vehicle");
/**
 * Object regrouping the different API requests available to communicate with the API using the `@monkvision/network`
 * package.
 */
exports.MonkApi = {
    getInspection: inspection_1.getInspection,
    createInspection: inspection_1.createInspection,
    addImage: image_1.addImage,
    updateTaskStatus: task_1.updateTaskStatus,
    startInspectionTasks: task_1.startInspectionTasks,
    getLiveConfig: liveConfigs_1.getLiveConfig,
    updateInspectionVehicle: vehicle_1.updateInspectionVehicle,
};
