"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MonkApiPermission = void 0;
/**
 * Enumeration of the API permissions included in the Monk authentication token.
 *
 * Note that this enum is not extensive and only declares permissions useful for the MonkJs SDK.
 */
var MonkApiPermission;
(function (MonkApiPermission) {
    MonkApiPermission["TASK_COMPLIANCES"] = "monk_core_api:compliances";
    MonkApiPermission["TASK_DAMAGE_DETECTION"] = "monk_core_api:damage_detection";
    MonkApiPermission["TASK_DAMAGE_IMAGES_OCR"] = "monk_core_api:images_ocr";
    MonkApiPermission["TASK_DAMAGE_REPAIR_ESTIMATE"] = "monk_core_api:repair_estimate";
    MonkApiPermission["TASK_WHEEL_ANALYSIS"] = "monk_core_api:wheel_analysis";
    MonkApiPermission["INSPECTION_CREATE"] = "monk_core_api:inspections:create";
    MonkApiPermission["INSPECTION_READ"] = "monk_core_api:inspections:read";
    MonkApiPermission["INSPECTION_READ_ALL"] = "monk_core_api:inspections:read_all";
    MonkApiPermission["INSPECTION_READ_ORGANIZATION"] = "monk_core_api:inspections:read_organization";
    MonkApiPermission["INSPECTION_UPDATE"] = "monk_core_api:inspections:update";
    MonkApiPermission["INSPECTION_UPDATE_ALL"] = "monk_core_api:inspections:update_all";
    MonkApiPermission["INSPECTION_UPDATE_ORGANIZATION"] = "monk_core_api:inspections:update_organization";
    MonkApiPermission["INSPECTION_WRITE"] = "monk_core_api:inspections:write";
    MonkApiPermission["INSPECTION_WRITE_ALL"] = "monk_core_api:inspections:write_all";
    MonkApiPermission["INSPECTION_WRITE_ORGANIZATION"] = "monk_core_api:inspections:write_organization";
})(MonkApiPermission = exports.MonkApiPermission || (exports.MonkApiPermission = {}));
