"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MonkActionType = void 0;
/**
 * Enumeration of the types of action that can be dispatched in the Monk state.
 */
var MonkActionType;
(function (MonkActionType) {
    /**
     * An inspection has been fetched from the API.
     */
    MonkActionType["GOT_ONE_INSPECTION"] = "got_one_inspection";
    /**
     * An image has been uploaded to the API.
     */
    MonkActionType["CREATED_ONE_IMAGE"] = "created_one_image";
    /**
     * One or more tasks have been updated.
     */
    MonkActionType["UPDATED_MANY_TASKS"] = "updated_many_tasks";
    /**
     * Clear and reset the state.
     */
    MonkActionType["RESET_STATE"] = "reset_state";
    /**
     * A vehicle has been updated.
     */
    MonkActionType["UPDATED_VEHICLE"] = "updated_vehicle";
})(MonkActionType = exports.MonkActionType || (exports.MonkActionType = {}));
