"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DamageType = void 0;
/**
 * Enumeration of the types of damages that the models can detect.
 */
var DamageType;
(function (DamageType) {
    DamageType["SCRATCH"] = "scratch";
    DamageType["DENT"] = "dent";
    DamageType["BROKEN_GLASS"] = "broken_glass";
    DamageType["BROKEN_LIGHT"] = "broken_light";
    DamageType["HUBCAP_SCRATCH"] = "hubcap_scratch";
    DamageType["MISSING_HUBCAP"] = "missing_hubcap";
    DamageType["SMASH"] = "smash";
    DamageType["BODY_CRACK"] = "body_crack";
    DamageType["MISSING_PIECE"] = "missing_piece";
    DamageType["RUSTINESS"] = "rustiness";
})(DamageType = exports.DamageType || (exports.DamageType = {}));
