"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Login = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_i18next_1 = require("react-i18next");
var network_1 = require("@monkvision/network");
var common_1 = require("@monkvision/common");
var monitoring_1 = require("@monkvision/monitoring");
var analytics_1 = require("@monkvision/analytics");
var Button_1 = require("../Button");
var i18n_1 = require("./i18n");
var Login_styles_1 = require("./Login.styles");
function getLoginErrorMessage(err) {
    if (err instanceof Error) {
        if (err.message === 'Popup closed') {
            return 'errors.popup-closed';
        }
    }
    return 'errors.unknown';
}
/**
 * This component is a ready-to-use Login page that is used throughout the different Monk webapps to handle
 * authentication.
 *
 * **Note : For this component to work properly, it must be the child of a `MonkAppStateProvider` component.**
 */
exports.Login = (0, common_1.i18nWrap)(function Login(_a) {
    var onLoginSuccessful = _a.onLoginSuccessful, lang = _a.lang, _b = _a.style, style = _b === void 0 ? {} : _b;
    (0, common_1.useI18nSync)(lang);
    var _c = (0, react_1.useState)(false), isExpired = _c[0], setIsExpired = _c[1];
    var loading = (0, common_1.useLoadingState)();
    var _d = (0, common_1.useMonkAppState)(), authToken = _d.authToken, setAuthToken = _d.setAuthToken, config = _d.config;
    var _e = (0, monitoring_1.useMonitoring)(), handleError = _e.handleError, setUserId = _e.setUserId;
    var analytics = (0, analytics_1.useAnalytics)();
    var _f = (0, network_1.useAuth)(), login = _f.login, logout = _f.logout;
    var t = (0, react_i18next_1.useTranslation)().t;
    var rootStyles = (0, common_1.useMonkTheme)().rootStyles;
    (0, react_1.useEffect)(function () {
        if (!authToken && !(config === null || config === void 0 ? void 0 : config.allowManualLogin)) {
            setAuthToken(null);
            setIsExpired(false);
            loading.onError('errors.missing-token');
        }
        if ((config === null || config === void 0 ? void 0 : config.requiredApiPermissions) &&
            authToken &&
            !(0, network_1.isUserAuthorized)(authToken, config.requiredApiPermissions)) {
            loading.onError('errors.insufficient-authorization');
        }
        if (authToken && (0, network_1.isTokenExpired)(authToken)) {
            setIsExpired(true);
            setAuthToken(null);
        }
    }, [authToken, loading]);
    var handleLogin = function () {
        setIsExpired(false);
        loading.start();
        login()
            .then(function (token) {
            if ((config === null || config === void 0 ? void 0 : config.requiredApiPermissions) &&
                !(0, network_1.isUserAuthorized)(token, config.requiredApiPermissions)) {
                loading.onError('errors.insufficient-authorization');
            }
            else {
                loading.onSuccess();
                onLoginSuccessful === null || onLoginSuccessful === void 0 ? void 0 : onLoginSuccessful();
                var userId = token ? (0, network_1.decodeMonkJwt)(token) : undefined;
                if (userId === null || userId === void 0 ? void 0 : userId.sub) {
                    setUserId(userId.sub);
                    analytics.setUserProperties({ authToken: userId.sub });
                }
            }
        })
            .catch(function (err) {
            var message = getLoginErrorMessage(err);
            loading.onError(message);
            handleError(err);
        });
    };
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ style: __assign(__assign(__assign({}, rootStyles), Login_styles_1.styles['container']), style) }, { children: [isExpired && (0, jsx_runtime_1.jsx)("div", __assign({ style: Login_styles_1.styles['errorMessage'] }, { children: t('errors.token-expired') })), loading.error && (0, jsx_runtime_1.jsx)("div", __assign({ style: Login_styles_1.styles['errorMessage'] }, { children: t(loading.error) })), authToken && (config === null || config === void 0 ? void 0 : config.allowManualLogin) && 'hi', authToken && (config === null || config === void 0 ? void 0 : config.allowManualLogin) && ((0, jsx_runtime_1.jsx)(Button_1.Button, __assign({ primaryColor: 'alert', loading: loading, onClick: logout }, { children: t('actions.log-out') }))), !authToken && (config === null || config === void 0 ? void 0 : config.allowManualLogin) && ((0, jsx_runtime_1.jsx)(Button_1.Button, __assign({ loading: loading, onClick: handleLogin }, { children: t('actions.log-in') })))] })));
}, i18n_1.i18nLogin);
