"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleTypeAssetsMap = void 0;
var types_1 = require("@monkvision/types");
var hatchback_1 = require("./hatchback");
var cuv_1 = require("./cuv");
var city_1 = require("./city");
var pickup_1 = require("./pickup");
var van_1 = require("./van");
var sedan_1 = require("./sedan");
var largeSuv_1 = require("./largeSuv");
var minivan_1 = require("./minivan");
var suv_1 = require("./suv");
exports.VehicleTypeAssetsMap = (_a = {},
    _a[types_1.VehicleType.HATCHBACK] = hatchback_1.VehicleTypeHatchbackAsset,
    _a[types_1.VehicleType.CUV] = cuv_1.VehicleTypeCuvAsset,
    _a[types_1.VehicleType.CITY] = city_1.VehicleTypeCityAsset,
    _a[types_1.VehicleType.PICKUP] = pickup_1.VehicleTypePickupAsset,
    _a[types_1.VehicleType.VAN] = van_1.VehicleTypeVanAsset,
    _a[types_1.VehicleType.SEDAN] = sedan_1.VehicleTypeSedanAsset,
    _a[types_1.VehicleType.LARGE_SUV] = largeSuv_1.VehicleTypeLargeSuvAsset,
    _a[types_1.VehicleType.MINIVAN] = minivan_1.VehicleTypeMinivanAsset,
    _a[types_1.VehicleType.SUV] = suv_1.VehicleTypeSuvAsset,
    _a);
