"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePictureTaken = void 0;
var react_1 = require("react");
var analytics_1 = require("@monkvision/analytics");
var useAddDamageMode_1 = require("./useAddDamageMode");
/**
 * Custom hook used to generate the callback called when the user has taken a picture to handle picture upload etc.
 */
function usePictureTaken(_a) {
    var sightState = _a.sightState, addDamageHandle = _a.addDamageHandle, uploadQueue = _a.uploadQueue, tasksBySight = _a.tasksBySight;
    var trackEvent = (0, analytics_1.useAnalytics)().trackEvent;
    return (0, react_1.useCallback)(function (picture) {
        var _a;
        sightState.setLastPictureTakenUri(picture.uri);
        var upload = addDamageHandle.mode === useAddDamageMode_1.PhotoCaptureMode.SIGHT
            ? {
                mode: addDamageHandle.mode,
                picture: picture,
                sightId: sightState.selectedSight.id,
                tasks: (_a = tasksBySight === null || tasksBySight === void 0 ? void 0 : tasksBySight[sightState.selectedSight.id]) !== null && _a !== void 0 ? _a : sightState.selectedSight.tasks,
            }
            : { mode: addDamageHandle.mode, picture: picture };
        uploadQueue.push(upload);
        if (addDamageHandle.mode === useAddDamageMode_1.PhotoCaptureMode.SIGHT) {
            sightState.takeSelectedSight();
        }
        else {
            trackEvent('AddDamage Captured', {
                mode: addDamageHandle.mode,
            });
        }
        addDamageHandle.updatePhotoCaptureModeAfterPictureTaken();
    }, [
        sightState.setLastPictureTakenUri,
        addDamageHandle.mode,
        sightState.selectedSight.id,
        tasksBySight,
        uploadQueue.push,
        sightState.takeSelectedSight,
        addDamageHandle.updatePhotoCaptureModeAfterPictureTaken,
    ]);
}
exports.usePictureTaken = usePictureTaken;
