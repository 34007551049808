"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeviceOrientation = void 0;
/**
 * Enumeration of the device orientations.
 */
var DeviceOrientation;
(function (DeviceOrientation) {
    /**
     * Portrait orientation (width < height).
     */
    DeviceOrientation["PORTRAIT"] = "portrait";
    /**
     * Landscape orientation (width > height).
     */
    DeviceOrientation["LANDSCAPE"] = "landscape";
})(DeviceOrientation = exports.DeviceOrientation || (exports.DeviceOrientation = {}));
