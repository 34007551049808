"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.damageTypeLabels = void 0;
var types_1 = require("@monkvision/types");
/**
 * The translated labels for each damage type available in the SDK.
 */
exports.damageTypeLabels = (_a = {},
    _a[types_1.DamageType.SCRATCH] = {
        en: 'Scratch',
        fr: 'Rayure',
        de: 'Kratzer',
        nl: 'Kras',
    },
    _a[types_1.DamageType.DENT] = {
        en: 'Dent',
        fr: 'Bosse',
        de: 'Beule',
        nl: 'Deuk',
    },
    _a[types_1.DamageType.BROKEN_GLASS] = {
        en: 'Broken Glass',
        fr: 'Bris de glace',
        de: 'Zerbrochenes Glas',
        nl: 'Gebroken glas',
    },
    _a[types_1.DamageType.BROKEN_LIGHT] = {
        en: 'Broken Light',
        fr: 'Feu cassé',
        de: 'Kaputtem Licht',
        nl: 'Gebroken licht',
    },
    _a[types_1.DamageType.HUBCAP_SCRATCH] = {
        en: 'Hubcap Scratch',
        fr: 'Enjoliveur rayé',
        de: 'Zerkratzte Radkappe',
        nl: 'Gebroken wieldop',
    },
    _a[types_1.DamageType.MISSING_HUBCAP] = {
        en: 'Missing Hubcap',
        fr: 'Enjoliveur manquant',
        de: 'Fehlende Radkappe',
        nl: 'Ontbrekende wieldop',
    },
    _a[types_1.DamageType.SMASH] = {
        en: 'Smash',
        fr: 'Collision',
        de: 'Zusammenstoß',
        nl: 'Botsing',
    },
    _a[types_1.DamageType.BODY_CRACK] = {
        en: 'Body Crack',
        fr: 'Fissure dans la carrosserie',
        de: 'Karosserieriss',
        nl: 'Carrosseriescheur',
    },
    _a[types_1.DamageType.MISSING_PIECE] = {
        en: 'Missing Piece',
        fr: 'Pièce manquante',
        de: 'Fehlendes Teil',
        nl: 'Ontbrekend onderdeel',
    },
    _a[types_1.DamageType.RUSTINESS] = {
        en: 'Rustiness',
        fr: 'Rouille',
        de: 'Rostigkeit',
        nl: 'Roest',
    },
    _a);
